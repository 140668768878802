import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(studio)": [6,[2]],
		"/auth": [~26],
		"/(studio)/hardware": [~7,[2]],
		"/(studio)/hardware/[id]": [~8,[2],[,3]],
		"/(studio)/report": [~9,[2]],
		"/(studio)/search": [~10,[2]],
		"/(studio)/search/hardware/[id]": [~11,[2]],
		"/(studio)/search/manufacturer/[id]": [~12,[2]],
		"/(studio)/search/software/[id]": [~13,[2]],
		"/(studio)/settings": [14,[2]],
		"/(studio)/settings/account": [~15,[2]],
		"/(studio)/settings/devices": [~16,[2]],
		"/(studio)/settings/notifications": [17,[2]],
		"/(studio)/settings/plugins": [~18,[2]],
		"/(studio)/settings/profile": [~19,[2]],
		"/(studio)/settings/subscription": [20,[2]],
		"/(studio)/settings/tags": [~21,[2]],
		"/(studio)/setups": [~22,[2]],
		"/(studio)/setups/[id]": [~23,[2],[,4]],
		"/(studio)/software": [~24,[2]],
		"/(studio)/software/[id]": [~25,[2],[,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';